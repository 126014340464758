<template>
  <div v-if="user.servieceCodeVisible" class="dialog">
    <div class="content">
      <img
        src="@/assets/images/icon_dialog_close.png"
        @click="closeDialog"
        class="close"
        alt=""
      />
      <div class="tit"></div>
      <div class="desc">扫码关注服务号，立即体验新功能</div>
      <div class="qrcode">
        <div @click="onRefresh" v-if="user.isOverDue" class="mask">
          点击刷新<i class="el-icon-refresh-right"></i>
        </div>
        <img :src="user.qrcodeUrl" alt="" />
      </div>
      <div @click="todayNoTip" class="tip">今日不再提示</div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { closeQcCode } from "@/api/serviceQrcode/index.js";
export default {
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: (state) => state.user, // 用户信息
    }),
  },
  mounted() {},
  watch: {},
  unmounted() {
    this.user.timer && clearTimeout(this.user.timer);
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations(["setServieceCodeVisible"]),
    ...mapActions(["fetchQrCode"]),
    onRefresh() {
      let source = "finishTeach";
      if (this.$route.name === "teachManage") {
        source = "teachManage";
      }
      this.fetchQrCode({ source, refresh: 1 });
    },
    closeDialog() {
      this.setServieceCodeVisible(false);
      this.user.timer && clearTimeout(this.user.timer);
    },
    // 今日不再提示
    todayNoTip() {
      closeQcCode().then(() => {
        this.closeDialog();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.45);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .content {
    position: absolute;
    background-color: #fff;
    border-radius: 8px;
    width: 320px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: #333333;
    padding: 30px 40px 30px;
    font-family: PingFangSC, PingFang SC;

    .close {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 22px;
      right: 22px;
      cursor: pointer;
    }
    .tit {
      font-size: 18px;
      margin-bottom: 12px;
    }
    .desc {
      font-size: 16px;
      margin-bottom: 30px;
    }
    .qrcode {
      position: relative;
      width: 240px;
      height: 240px;
      margin-bottom: 30px;
      .mask {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        color: #fff;
        top: 0;
        left: 0;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tip {
      text-align: center;
      font-size: 12px;
      cursor: pointer;
      color: #666666;
    }
  }
}
</style>
