import JSEncrypt from "jsencrypt";

const PUBLIC_KEY =
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnXnrmnWtxXQLC9i4CluYFlD0Nv55VM392dzQwLT23E7MYX63Af2nie2iY0uyDYWlXkqs8Z78TWLhv1gLjghGUsYffszKZ8Uoxxm4oGrN5NVANUa50u2xoVQPrquoEazHxfVs8zbKakVcvRbPvonSP0mOzhbnJBn6XNeGMwbS2fMsQR9vBA7zew4lXJR6aOcx3Z8ofll6XQ5q3mEzViNWpFhZjzpYGg7z8lXkeR3X6sXxLE1MDHh/IkV/g5g9AkzJ0Ea+MQuhANFMiAKJZ0vxxIJiIf/I8WoXbjOv0+atatgjI0AoC/quwR3bD2rNNdMCD13seY54UaUVcXXrlJOq2QIDAQAB";

// 加密
export function rsaEncrypt(word) {
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  var encrypted = encrypt.encrypt(word);
  return encrypted;
}

// 正则校验密码
export function passwordValidate(str) {
  let obj = { check: false, tips: "" };
  var rC = {
    lW: "[a-z]", // 小写字母
    uW: "[A-Z]", // 大写字母
    nW: "[0-9]", // 数字
    cW: "[\u4E00-\u9FA5]", // 中文
  };
  function Reg(str, rStr) {
    var reg = new RegExp(rStr);
    if (reg.test(str)) return true;
    else return false;
  }
  if (str.length < 8 || str.length > 16) {
    obj = {
      check: false,
      tips: "请输入8-16位的密码",
    };
  } else {
    var tR = {
      l: Reg(str, rC.lW),
      u: Reg(str, rC.uW),
      n: Reg(str, rC.nW),
      c: Reg(str, rC.cW),
    };
    if (tR.l && tR.u && tR.n && !tR.c) {
      // 密码符合要求
      obj = {
        check: true,
        tips: "",
      };
    } else {
      obj = {
        check: false,
        tips: "密码必须含有“小写字母”、“大写字母”、“数字”，不能含有“中文”",
      };
    }
  }
  return obj;
}
