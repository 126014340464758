<template>
  <el-scrollbar v-if="isStaticPage" class="el-scrollbar_x">
    <div class="layout">
      <el-container
        class="wrapper el-scrollbar-x"
        :class="isHiddenSign ? 'only-main' : 'all-main'"
        style="height: 100%"
      >
        <!-- 头部 start -->
        <div class="header--wrapper" v-if="isShow && !isHiddenSign">
          <el-header class="header">
            <div class="container-body">
              <!-- logo start -->
              <div class="head-left">
                <div
                  tag="h1"
                  class="header__logo"
                  @click="$route.name !== 'hdCourseDetails' ? goHome : null"
                >
                  <img
                    class="header__img"
                    :style="
                      $route.name === 'hdCourseDetails' ? 'cursor: auto;' : ''
                    "
                    src="@/assets/images/subjectType/logo_new.png"
                    :alt="$t('el.common.centerName')"
                    :title="$t('el.common.centerName')"
                  />
                </div>
                <div class="menu-box">
                  <div
                    class="menu-item first-item"
                    :style="
                      $route.name === 'hdCourseDetails' ? 'cursor: auto;' : ''
                    "
                    @click="
                      $route.name !== 'hdCourseDetails'
                        ? changeItem('first')
                        : null
                    "
                  >
                    <div class="menu-p">
                      <div
                        class="menu-p-li"
                        :class="[
                          activeSecondRouteName == 'home' ||
                          activeSecondRouteName == 'myTimeTable' ||
                          (activeSecondRouteName == 'CourseDetails' &&
                            detailsFromPage === 'home') ||
                          activeSecondRouteName == 'myCourses'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <!--
                        || activeSecondRouteName == 'CourseDetails' ||
                          activeSecondRouteName == 'hdCourseDetails' ||
                          activeSecondRouteName == 'courseList'
                      -->
                        <img
                          v-if="
                            activeSecondRouteName == 'home' ||
                            activeSecondRouteName == 'myTimeTable' ||
                            (activeSecondRouteName == 'CourseDetails' &&
                              detailsFromPage === 'home') ||
                            activeSecondRouteName == 'myCourses'
                          "
                          class="book__img"
                          src="@/assets/images/icon_nav_curriculum_selected@2x.png"
                          :alt="$t('el.common.centerName')"
                          :title="$t('el.common.centerName')"
                        />
                        <img
                          v-else
                          class="book__img"
                          src="@/assets/images/icon_nav_curriculum_normal@2x.png"
                          :alt="$t('el.common.centerName')"
                          title="$t('el.common.centerName')"
                        />
                        <span
                          class="span"
                          :class="[
                            activeSecondRouteName == 'home' ||
                            activeSecondRouteName == 'myTimeTable' ||
                            (activeSecondRouteName == 'CourseDetails' &&
                              detailsFromPage === 'home') ||
                            activeSecondRouteName == 'myCourses'
                              ? ' select'
                              : 'normal',
                          ]"
                          >{{ $t("el.home.teachingPreparation") }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="menu-item"
                    @click="changeItem('second')"
                    v-if="
                      teachManageAccess &&
                      canAccess &&
                      $store.state.user &&
                      $store.state.user.eduAuth &&
                      $route.name !== 'hdCourseDetails'
                    "
                  >
                    <div class="menu-p">
                      <div
                        :class="[
                          activeSecondRouteName == 'tabManage' ||
                          (activeSecondRouteName == 'CourseDetails' &&
                            detailsFromPage === 'list') ||
                          activeSecondRouteName == 'hdCourseDetails' ||
                          activeSecondRouteName == 'courseList'
                            ? 'active'
                            : '',
                        ]"
                      >
                        <img
                          v-if="
                            activeSecondRouteName == 'tabManage' ||
                            (activeSecondRouteName == 'CourseDetails' &&
                              detailsFromPage === 'list') ||
                            activeSecondRouteName == 'hdCourseDetails' ||
                            activeSecondRouteName == 'courseList'
                          "
                          class="book__img"
                          src="@/assets/images/icon_nav_administration_selected@2x.png"
                          :alt="$t('el.common.centerName')"
                          :title="$t('el.common.centerName')"
                        />
                        <img
                          v-else
                          class="book__img"
                          src="@/assets/images/icon_nav_administration_normal@2x.png"
                          :alt="$t('el.common.centerName')"
                          :title="$t('el.common.centerName')"
                        />
                        <span
                          class="span"
                          :class="[
                            activeSecondRouteName == 'tabManage' ||
                            (activeSecondRouteName == 'CourseDetails' &&
                              detailsFromPage === 'list') ||
                            activeSecondRouteName == 'hdCourseDetails' ||
                            activeSecondRouteName == 'courseList'
                              ? 'select'
                              : 'normal',
                          ]"
                          >{{ $t("el.home.teachingManage") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      boardAccess &&
                      canAccess &&
                      (~~regulator === 1 || $store.state.user.eduAuth) &&
                      $route.name !== 'hdCourseDetails'
                    "
                    class="menu-item third-item"
                    @click="getAreaStatic"
                  >
                    <div class="menu-p">
                      <div class="menu-p-li">
                        <i class="el-icon-data-line"></i>
                        <span class="span">{{ $t("el.home.billboard") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <el-select v-model="selLocale" placeholder="">
                <el-option
                  key="zh_CN"
                  label="简体中文"
                  value="zh_CN"
                ></el-option>
                <el-option
                  key="en_US"
                  label="English"
                  value="en_US"
                ></el-option>
              </el-select> -->

              <!-- logo start -->
              <div v-if="$route.name !== 'hdCourseDetails'" class="head-right">
                <span class="back-to-saas" @click="handleBackToSaas">{{
                  $t("el.saas.backToSaas")
                }}</span>
                <div v-btn="'header-operation-center'" class="head-right-btn">
                  <span @click="handleImportRecord">{{
                    $t("el.planCourse.operationCenter")
                  }}</span>
                </div>
                <div
                  v-if="$route.name !== 'hdCourseDetails'"
                  class="change-language"
                >
                  <div class="logo-bar">
                    <!-- <i class="logo-icon"></i> -->
                    <!-- <span class="logo-txt">海亮教育</span> -->
                    <span class="lang-txt" @click="switchLanguage">{{
                      $t("el.common.language")
                    }}</span>
                  </div>
                </div>
                <div class="user-center">
                  <!-- <div
                    class="course-manage"
                    :class="[downFlag ?  'down-active': '']"
                    @mousedown="setDownClass"
                    @mouseleave="leaveClass"
                    @click="goClassManage"
                  >班级管理</div> -->

                  <div class="manage-right">
                    <el-dropdown>
                      <div class="drop__menu">
                        <img
                          v-if="gender == 2"
                          class="nav-avatar"
                          src="@/assets/images/subjectType/pic_face_woman@2x.png"
                          :alt="$t('el.common.centerName')"
                          :title="$t('el.common.centerName')"
                        />
                        <img
                          v-else
                          class="nav-avatar"
                          src="@/assets/images/subjectType/pic_face_man@2x.png"
                          :alt="$t('el.common.centerName')"
                          :title="$t('el.common.centerName')"
                        />
                        <el-tooltip
                          placement="top"
                          v-if="staffName && staffName.length > 12"
                        >
                          <div slot="content">{{ staffName }}</div>
                          <div
                            class="username"
                            :class="[
                              staffName.length > 12 ? 'hide-username' : '',
                            ]"
                          >
                            {{ staffName }}
                          </div>
                        </el-tooltip>
                        <div class="username" v-else>{{ staffName }}</div>
                        <img
                          class="drop__img pointer"
                          src="@/assets/images/icon_navtop_open@2x.png"
                          :alt="$t('el.common.centerName')"
                          :title="$t('el.common.centerName')"
                        />
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <div class="drop__item drop__item--title">
                          <img
                            src="@/assets/images/pic_navtop_hi@2x.png"
                            :alt="$t('el.common.centerName')"
                            :title="$t('el.common.centerName')"
                          /><span>{{ $t("el.home.welcomeAIClass") }}</span>
                        </div>
                        <!-- <el-dropdown-item
                          @click.native="goMyCenter"
                          class="drop__item"
                          v-if="canAccess"
                        >
                          <img
                            src="@/assets/images/icon_navleft_home_normal@2x.png"
                            :alt="$t('el.common.centerName')"
                            :title="$t('el.common.centerName')"
                          /><span>{{
                            $t("el.home.myCenter")
                          }}</span></el-dropdown-item
                        > -->
                        <el-dropdown-item
                          @click.native="goClassManage"
                          class="drop__item"
                          v-if="canAccess"
                          ><img
                            src="@/assets/images/icon_navleft_class_normal@2x.png"
                            :alt="$t('el.common.centerName')"
                            :title="$t('el.common.centerName')"
                          /><span>{{
                            $t("el.home.classManagement")
                          }}</span></el-dropdown-item
                        >
                        <!-- <el-dropdown-item
                          @click.native="openPwdDialog"
                          class="drop__item drop__item--logout"
                          ><div class="back">
                            <img
                              class="back__img pointer"
                              src="@/assets/images/icon-update-pwd.png"
                              :alt="$t('el.common.centerName')"
                              :title="$t('el.common.centerName')"
                            />
                          </div>
                          <span>{{
                            $t("el.home.updatePwd")
                          }}</span></el-dropdown-item
                        > -->
                        <el-dropdown-item
                          @click.native="logout"
                          class="drop__item drop__item--logout"
                          style="border-top: 0 solid #e6e6e6"
                          ><div class="back">
                            <img
                              class="back__img pointer"
                              src="@/assets/images/icon_nav_quit@2x.png"
                              :alt="$t('el.common.centerName')"
                              :title="$t('el.common.centerName')"
                            />
                          </div>
                          <span>{{
                            $t("el.home.logOut")
                          }}</span></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>

                  <!-- <div class="product-right" @click="$router.push('/portal')">产品介绍</div> -->
                  <div class="admin-line" v-if="~~userType !== 0"></div>
                  <div
                    class="admin-manage"
                    @click="goAdminManage"
                    v-if="~~userType !== 0"
                  >
                    {{ $t("el.home.backEndManagement") }}
                  </div>
                </div>
              </div>
            </div>
          </el-header>
        </div>
        <!-- 头部 end -->
        <el-main>
          <!-- <el-scrollbar> -->
          <div class="container">
            <template>
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive" />
              </keep-alive>
              <router-view v-if="!$route.meta.keepAlive" />
            </template>
          </div>
          <!-- </el-scrollbar> -->
        </el-main>
        <!-- <div class="coperight" v-if="isShow && !isHiddenSign">
          Copyright {{ year }} {{ $t("el.common.copyRight") }}
          <img src="@/assets/images/zghb.png" />
          {{ $t("el.common.copyRightNum") }}
        </div> -->
      </el-container>
    </div>

    <!-- 服务号扫码弹窗 -->
    <ServiceQrcode></ServiceQrcode>

    <el-backtop
      target="#app .el-scrollbar__wrap"
      v-if="
        $route.name == 'usage' ||
        $route.name == 'course' ||
        $route.name == 'compared'
      "
    >
      <div class="el-backtop-content flex flex-column">
        <i class="el-icon-caret-top"></i>
        <div class="el-backtop-text">{{ $t("el.home.backTop") }}</div>
      </div>
    </el-backtop>
    <forgot-password-dialog
      :dialogVisible="showForgetPwdDialog"
      :mobile="mobile"
      :readonly="mobileReadonly"
      :pwdType="'UPDATE'"
      :title="$t('el.home.updatePwd')"
      @close-dialog="closePwdDialog"
      @update-pwd-success="updatePwdSuccess"
    ></forgot-password-dialog>
    <import-record-dialog
      :dialogTitle="$t('el.planCourse.importedRecords')"
      :dialogVisible="dialogVisibleImport"
      @handleClose="handleCloseImport"
      v-if="dialogVisibleImport"
    />
  </el-scrollbar>
  <div class="datav-layout" v-else>
    <template>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import { windowOpen } from "@/utils";
import {
  getToken,
  getLoginFrom,
  toZnxyLogin,
  removeLoginFrom,
} from "@/core/auth";
import { mapGetters } from "vuex";
import { setCookieLanguage } from "../../core/auth";
import ForgotPasswordDialog from "@/components/biz-components/ForgotPasswordDialog.vue";
import ImportRecordDialog from "@/components/biz-components/ImportRecordDialog.vue";
import { checkAuthMenu } from "@/utils/auth";
import { getPathByName } from "@/router";
import ServiceQrcode from "../../components/ServiceQrcode.vue";

export default {
  name: "layout",
  components: {
    ForgotPasswordDialog,
    ImportRecordDialog,
    ServiceQrcode,
  },
  data() {
    return {
      active: "first",
      selLocale: "zh_CN",
      showForgetPwdDialog: false,
      mobile: "",
      mobileReadonly: false,
      isShow: true,
      year: new Date().getFullYear(),
      downFlag: false,
      activeSecondRouteName: "home",
      detailsFromPage: "",
      staticNames: ["statisticalBoard"],
      dialogVisibleImport: false, // 导入记录
    };
  },
  computed: {
    ...mapGetters([
      "staffName",
      "gender",
      "regulator",
      "canAccess",
      "isHiddenSign",
      "userType",
      "teachManageAccess", // 教务管理模块权限
    ]),
    isStaticPage() {
      if (this.staticNames.indexOf(this.$route.name) > -1) {
        return false;
      } else {
        return true;
      }
    },
    // 数据看板页面权限
    boardAccess() {
      // return checkAuthMenu("/board/statisticalBoard");
      // 学校应用端-隐藏区域看板菜单
      return false;
    },
  },
  created() {
    if (
      this.$route.name === "Auth" ||
      this.$route.name === "Resource" ||
      this.staticNames.indexOf(this.$route.name) > -1
    ) {
      this.isShow = false;
    }
    if (this.$route.name === "classManage") {
      this.active = "three";
    }
    this.selLocale = this.$i18n.locale;

    let detailsFromPage = localStorage.getItem("detailsFromPage");
    if (detailsFromPage && detailsFromPage === "courseList") {
      this.detailsFromPage = "list";
    } else {
      this.detailsFromPage = "home";
    }
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.activeSecondRouteName =
          newVal && newVal.matched[1] && newVal.matched[1].name;
        // console.log("activeSecondRouteName", this.activeSecondRouteName);
      },
      immediate: true,
    },
    // 切换多语言
    selLocale(newValue) {
      this.$i18n.locale = newValue;
      localStorage.setItem("language", newValue);
      setCookieLanguage(newValue);
      // console.log( this.$i18n.locale);
      // console.log(this.$t("el.home.teachingManage"));
      // console.log(this.$i18n.t("el.home.teachingManage"));
      window.document.title = this.$t(this.$route.meta.title);
    },
    // "$route.name"(newVal, oldVal) {
    //   if (newVal == "home") {
    //     this.active = "first";
    //   }
    // }
  },
  methods: {
    openPwdDialog() {
      this.mobile = this.$store.getters.getMobile;
      this.mobileReadonly = true;
      this.showForgetPwdDialog = true;
    },
    closePwdDialog() {
      console.log("closePwdDialog");
      this.showForgetPwdDialog = false;
    },
    switchLanguage() {
      if (this.$i18n.locale === "zh_CN") {
        this.selLocale = "en_US";
      } else {
        this.selLocale = "zh_CN";
      }
    },
    // 修改密码成功
    updatePwdSuccess() {
      this.logout();
    },
    setDownClass() {
      this.downFlag = true;
      this.active = "";
    },
    leaveClass() {
      this.downFlag = false;
    },
    goAdminManage() {
      const token = getToken();
      const Timestamp = new Date().getTime();
      let url = "";
      let loginFrom = getLoginFrom() || this.$route.query.loginFrom || "";
      if (loginFrom) {
        url =
          process.env.VUE_APP_FONT_API +
          `/sso/auth?Authorization=${token}&Timestamp=${Timestamp}&loginFrom=${loginFrom}`;
      } else {
        url =
          process.env.VUE_APP_FONT_API +
          `/sso/auth?Authorization=${token}&Timestamp=${Timestamp}`;
      }
      // process.env.VUE_APP_FONT_API + `/`;
      windowOpen(url, "_blank");
    },
    goHome() {
      this.$router.replace({
        name: "home",
      });
    },
    changeItem(flag) {
      this.active = flag;
      var pathname = flag === "first" ? "home" : this.teachManageAccess; // "teachManage";
      if (pathname !== this.activeSecondRouteName) {
        this.$router.push({
          name: pathname,
        });
      }
    },
    /**
     * 初始化数据
     */
    init() {},
    /**
     * 退出登录
     */
    logout() {
      const loading = this.$loading({
        text: this.$t("el.common.loggingOut"),
      });
      this.$store
        .dispatch("logout")
        .then(() => {
          loading.close();
          window.localStorage.clear();
          window.sessionStorage.clear();
          if (getLoginFrom() || this.$route.query.loginFrom) {
            // console.log('this.$route.query.loginFrom',this.$route.query.loginFrom);
            removeLoginFrom();
            toZnxyLogin();
          } else {
            this.$router.push("/login");
          }
        })
        .catch(() => {
          loading.close();
          window.localStorage.clear();
          window.sessionStorage.clear();
          this.$router.push("/login");
        });
    },
    /**
     * 修改密码
     */
    handleChangePassword() {
      this.$refs.xmChangePassword.init(this.staffCode);
    },
    goClassManage() {
      this.active = "three";
      // 管理班级
      this.$router.push({
        name: "classManage",
      });
    },
    goMyCenter() {
      this.active = "four";
      // 管理班级
      this.$router.push({
        name: "myCenter",
      });
    },
    // 跳转区域看板
    getAreaStatic() {
      let routeData = this.$router.resolve({
        name: "statisticalBoard",
        query: {
          staticType: 2,
        },
      });
      windowOpen(routeData.href, "_blank");
    },
    handleImportRecord() {
      this.dialogVisibleImport = true;
    },
    handleCloseImport() {
      this.dialogVisibleImport = false;
    },
    // 返回saas
    handleBackToSaas() {
      const tn =
        localStorage.getItem("tokenKey") === "Saas_Token"
          ? ""
          : `?tn=${localStorage.getItem("tokenKey")}`;
      window.location.href =
        localStorage.getItem("saasSource") === "sjSaasFront"
          ? window.baseConfig.VUE_APP_SAAS_FRONT_URL
          : window.baseConfig.VUE_APP_SAAS_ADMIN_URL + tn;
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  position: relative;
  background: #f6f8fc;
  min-width: 1200px;
}
// 头部
.header--wrapper {
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // z-index: 10;
  // background-color: #fff;
}
.menu-p {
  width: 90px;
  height: 71px;
  text-align: center;
  .menu-p-li {
    text-align: center;
  }
}
.header {
  width: 100%;
  height: 64px;
  line-height: 64px;
  // overflow: hidden;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px 0px rgba(229, 233, 242, 1);
  .container-body {
    padding: 0 0 0 0;
    display: flex;
    justify-content: space-between;
  }
}
.en-status {
  .logo-bar {
    line-height: 80px;
  }
  .header__logo {
    white-space: nowrap;
    height: 100%;
    line-height: 80px;
    font-size: 22px;
    color: #015699;
    padding-left: 50px;
    margin: 0;
  }
  .menu-box .menu-item .span {
    font-size: 13px;
    line-height: 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: -24px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    height: 34px;
    justify-content: center;
    align-items: center;
  }
  .active {
    width: 100px;
    height: 91px;
    background: #6049ff;
    -webkit-box-shadow: 0px 5px 15px 0px rgb(172 178 193 / 36%);
    box-shadow: 0px 5px 15px 0px rgb(172 178 193 / 36%);
    border-radius: 0px 0px 5px 5px;
    text-align: center;
    position: absolute;
    z-index: 99;
  }
  .el-header {
    background-color: #fff;
    padding: 0 0px 0 0px;
    height: 80px !important;
    line-height: 60px;
  }
}
.head-left {
  display: flex;
}
.header__logo {
  white-space: nowrap;
  height: 100%;
  font-size: 22px;
  color: #015699;
  padding-left: 50px;
  margin: 0;
  img {
    width: 162px;
    vertical-align: middle;
    cursor: pointer;
  }
}
.menu-box {
  width: 33.27%;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  .first-item {
    margin-right: 28px;
  }
  .third-item {
    margin-left: 28px;
    .el-icon-data-line {
      font-size: 24px;
      margin-bottom: 2px;
      display: inline-block;
      vertical-align: text-bottom;
      color: #646c95;
    }
    .span {
      color: #646c95;
    }
  }
  .menu-item {
    text-align: center;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }
    .span {
      font-size: 13px;
      line-height: 17px;
      display: flex;
      margin-top: -24px;
      // margin-left: 4px;
      justify-content: center;
    }
  }
}
.user-center {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .el-icon-user-solid {
    font-size: 32px;
    margin-left: 12px;
    vertical-align: middle;
  }
  .username {
    display: inline-block;
    line-height: 60px;
    font-size: 15px;
    color: rgba(50, 50, 50, 1);
    line-height: 20px;
    letter-spacing: 1px;
  }
  .hide-username {
    width: 220px;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-dropdown {
    float: right;
  }
}
.not-auth {
  margin-top: 120px;
}
.normal {
  color: rgba(100, 108, 149, 1);
}
.select {
  color: rgba(255, 255, 255, 1);
}
.course-manage {
  width: 115px;
  height: 38px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 6px 0px rgba(111, 138, 163, 0.16);
  border-radius: 19px;
  font-size: 14px;
  color: rgba(100, 108, 149, 1);
  line-height: 19px;
  letter-spacing: 1px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 44px;
  cursor: pointer;
}
.back {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 6px 0px rgba(111, 138, 163, 0.16);
  margin-left: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 24px;
  .back__img {
    width: 20px;
    height: 20px;
  }
}
.drop__menu {
  cursor: pointer;
  min-width: 120px;
  .drop__img {
    width: 18px;
    margin-left: 8px;
    vertical-align: middle;
  }
  .nav-avatar {
    width: 32px;
    border-radius: 50%;
    line-height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .username {
    vertical-align: middle;
  }
}
.el-dropdown-menu__item {
  padding: 0 18px;
}
.drop__item {
  margin: 8px 0;
  img {
    width: 18px;
    vertical-align: middle;
    margin-right: 6px;
  }
  span {
    vertical-align: middle;
    font-size: 14px;
    color: #464646;
  }
  &--title {
    margin-bottom: 14px;
    img {
      width: 24px;
      margin-right: 9px;
    }
    span {
      font-size: 13px;
      color: #282828;
      font-family: MicrosoftYaHei;
    }
  }
  &--logout {
    display: flex;
    align-items: center;
    padding: 6px;
    padding-bottom: 0;
    margin-left: 10px;
    border-top: 1px solid #e6e6e6;
    &:hover {
      background: none;
      span {
        color: @themeBlue;
      }
    }
    .back {
      width: 30px;
      height: 30px;
      margin: 0;
      margin-right: 8px;
      img {
        margin-right: 0;
        width: 14px;
        height: 14px;
      }
    }
  }
}
.manage-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-right {
  width: 86px;
  height: 30px;
  background: #faf9ff;
  border-radius: 16px;
  border: 1px solid rgba(96, 73, 255, 0.14);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @themeBlue;
  line-height: 30px;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
}
@media screen and (min-width: 1620px) {
  .manage-right {
    padding-right: 60px;
  }
}
@media screen and (max-width: 1619px) {
  .manage-right {
    padding-right: 27px;
  }
}
.el-dropdown-menu {
  padding: 10px 16px;
}
.dropdown-menu--logout {
  padding: 10px 0;
}
.active {
  width: 90px;
  height: 71px;
  background: @themeBlue;
  box-shadow: 0px 5px 15px 0px rgba(172, 178, 193, 0.36);
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  position: absolute;
  z-index: 99;
}

.el-container {
  min-height: 100vh;
}
.el-header {
  background-color: #fff;
  padding: 0 0px 0 0px;
  height: 60px;
  line-height: 60px;
}
.el-aside {
  background-color: #002140;
  color: #fff;
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
}
.only-main {
  /deep/ .el-main {
    padding: 0px 0 40px 0;
    // max-height: calc(100vh - 92px);
    // height: -moz-calc(100vh - 92px);
    // height: -webkit-calc(100vh - 92px);
    position: relative;
    overflow: auto;
  }
}
.all-main {
  /deep/ .el-main {
    padding: 0px 0 40px 0;
    max-height: calc(100vh - 64px);
    // height: -moz-calc(100vh - 92px);
    // height: -webkit-calc(100vh - 92px);
    position: relative;
    overflow: auto;
  }
}
.el-menu {
  border-right: 0;
}
.container {
  flex: 1;
  // overflow-y: auto;
  // overflow-x: hidden;
  // height: calc(100vh - 91px);
}
.head-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back-to-saas {
    font-size: 14px;
    cursor: pointer;
    padding: 0 8px;
    color: @themeNewPurple;
  }
  .head-right-btn {
    span {
      border: 1px solid @themeNewPurple;
      padding: 8px;
      color: @themeNewPurple;
      border-radius: 4px;
      font-size: 14px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .change-language {
    margin-left: 20px;
    margin-right: 20px;
    .logo-txt {
      margin-left: 10px;
      @include font-bold;
      font-size: 20px;
      color: #ffffff;
    }
    .lang-txt {
      cursor: pointer;
    }
  }
}

.el-footer {
  padding: 15px 15px 20px 215px;
  height: auto !important;
}
/deep/ .el-scrollbar__wrap {
  // height: calc(100vh - 96px);
  // overflow-x: hidden;
  // height: 100%;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
}
/deep/ .el-scrollbar {
  // flex: 1;
  // overflow: scroll;
}
.el-scrollbar {
  // overflow: scroll;
}
.coperight {
  font-size: 12px;
  color: rgba(163, 163, 163, 1);
  text-align: center;
  height: 32px;
  line-height: 32px;
  background: rgba(65, 66, 68, 1);
  img {
    width: 12px;
  }
}
.breadcrumb {
  &-item {
    float: left;
    font-size: 14px;
    // font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(112, 114, 118, 1);
    &-icon {
      margin: 0 3px;
    }
  }
  &-item:last-child {
    color: rgba(49, 49, 49, 1);
  }
}
.wrapper {
  display: flex;
  display: -webkit-flex; /* Safari */
  // min-height: 100vh;
  flex-direction: column;
}
.admin-manage {
  width: 34px;
  font-size: 16px;
  font-weight: bold;
  // color: rgba(73, 124, 240, 1);
  color: @themeBlue;
  line-height: 22px;
  padding: 0 19px;
  box-sizing: content-box;
  cursor: pointer;
}
.en-status {
  .admin-manage {
    width: 84px;
    font-size: 16px;
    font-weight: bold;
    // color: rgba(73, 124, 240, 1);
    color: @themeBlue;
    line-height: 22px;
    padding: 0 19px;
    box-sizing: content-box;
    cursor: pointer;
  }
}
.el-scrollbar {
  height: 100%;
}
// /deep/.el-scrollbar__bar {
//   display: none;
// }
.admin-line {
  width: 1px;
  height: 39px;
  // margin-left: 8px;
  background: linear-gradient(
    180deg,
    rgba(100, 108, 149, 0) 0%,
    rgba(100, 108, 149, 0.5) 51%,
    rgba(100, 108, 149, 0) 100%
  );
}
.down-active {
  box-shadow: 0 3px 6px 0 rgba(86, 137, 242, 0.21);
}

// 回顶部
.el-backtop {
  background: @themeBlue;
  box-shadow: 0px 2px 4px 0px rgba(73, 124, 240, 0.12);
  border-radius: 4px;
  width: 40px;
  height: 33px;
}
.el-backtop-content {
  text-align: center;
}
.el-backtop-text {
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  transform: scale(0.833);
  transform-origin: top;
  margin-top: -2px;
}
.el-icon-caret-top {
  color: #fff;
  font-size: 18px;
  margin-top: -4px;
}
</style>
