<template>
  <div class="forget-dialog">
    <el-dialog
      :visible.sync="forgotDialogVisible"
      custom-class="dialog-class"
      :title="title"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="$i18n.locale === 'zh_CN' ? '40%' : '60%'"
      @close="closeDialog"
    >
      <div style="text-align: center">
        <!-- 表单 start -->
        <el-form
          :show-message="false"
          :model="pwdFormData"
          :rules="rules"
          ref="forgetForm"
          class="input-form"
        >
          <!--  @keyup.enter.native="handleNext" -->
          <!-- 输入验证码 start -->
          <div class="steps-validate">
            <!-- <p>验证码已发送至你的注册手机</p> -->
            <el-form-item
              prop="mobile"
              :label="$t('el.forgotPwd.MobileNumber')"
              class="flex"
            >
              <el-input
                v-model="pwdFormData.mobile"
                :readonly="readonly"
                :disabled="readonly"
                :placeholder="$t('el.forgotPwd.EnterMobileNumber')"
              />
              <el-button
                type="primary"
                style="margin-left: 20px"
                @click="sendValidate"
                :disabled="msgSending || sendSuccess"
                >{{ countdownText }}</el-button
              >
            </el-form-item>
            <el-form-item
              prop="code"
              class="flex"
              :label="$t('el.forgotPwd.VerificationCodes')"
            >
              <el-input
                v-model="pwdFormData.code"
                :maxlength="6"
                class="steps-validate-code"
                :placeholder="$t('el.forgotPwd.EnterVerificationCode')"
                :readonly="readonlyData"
                @focus="focusFn"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              class="flex"
              prop="password"
              :label="$t('el.forgotPwd.SetPassword')"
            >
              <el-input
                v-model="pwdFormData.password"
                :placeholder="$t('el.forgotPwd.Charactersnumbers')"
                type="password"
                :readonly="readonlyData"
                @focus="focusFn"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="flex"
              prop="affirmPassword"
              :label="$t('el.forgotPwd.ConfirmPassword')"
            >
              <el-input
                v-model="pwdFormData.affirmPassword"
                type="password"
                :readonly="readonlyData"
                @focus="focusFn"
                :placeholder="$t('el.forgotPwd.ConfirmPasswordAgain')"
              />
            </el-form-item>
          </div>
          <!-- 输入验证码 start -->
        </el-form>
        <!-- 表单 end -->
        <!-- 提交按钮 start -->
        <span slot="footer" class="dialog-footer">
          <el-button
            :loading="confirmLoading"
            type="primary"
            @click="handleNext"
            >{{ $t("el.common.confirm") }}</el-button
          >
          <el-button
            @click="forgotDialogVisible = false"
            style="margin-left: 20px"
            >{{ $t("el.common.cancel") }}</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { sendSms, updatePassword } from "@/api/user";
import { rsaEncrypt } from "@/utils/rsaCrpto.js";
export default {
  name: "ForgotPasswordDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "修改密码",
    },
    mobile: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    pwdType: {
      type: String,
      default: "RETRIEVE", // 枚举: REG,LOGIN,UPDATE,RETRIEVE
    },
  },
  data() {
    const _this = this;
    return {
      // 提交中
      confirmLoading: false,
      forgotDialogVisible: false,
      readonlyData: true,
      canSubmit: true,
      // 步骤位置
      steps: 0,
      // 表单数据
      pwdFormData: {
        // 工号
        username: undefined,
        // 手机号码
        mobile: undefined,
        // 密码
        password: undefined,
        // 确认密码
        affirmPassword: undefined,
        // 验证码
        code: undefined,
      },
      // 发送中
      msgSending: false,
      // 成功发送
      sendSuccess: false,
      // 倒计时
      timing: 60,
    };
  },
  watch: {
    mobile(val) {
      console.log("mobile", val);
      this.pwdFormData.mobile = val;
    },
    dialogVisible(val) {
      console.log("dialogVisible", val);
      this.forgotDialogVisible = val;
    },
  },
  computed: {
    /**
     * 倒计时文本
     */
    countdownText() {
      if (this.msgSending) return this.$t("el.forgotPwd.SendingSMSMessage");
      return this.sendSuccess
        ? `${this.timing} s`
        : this.$t("el.forgotPwd.ObtainingCode");
    },
    // 错误信息
    errorMessages() {
      return [
        {
          message: this.$t("el.forgotPwd.passwordnotMeet"),
          pass: true,
        },
        {
          message: this.$t("el.forgotPwd.passwordDiff"),
          pass: true,
        },
        {
          message: this.$t("el.forgotPwd.enternewpassword"),
          pass: true,
        },
      ];
    },
    // 表单校验
    rules() {
      const _this = this;
      return {
        mobile: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("el.forgotPwd.EnterMobileNumber"),
          },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: this.$t("el.forgotPwd.CorrectMobileNumber"),
            trigger: "blur",
          },
        ],
        username: [{ required: true, trigger: "blur", message: "请输入工号" }],
        code: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("el.forgotPwd.EnterVerificationCode"),
          },
          {
            min: 6,
            max: 6,
            trigger: "blur",
            message: this.$t("el.forgotPwd.VerificationCode"),
          },
        ],
        password: [
          {
            validator(rule, value, callback) {
              const res = !!value;
              _this.errorMessages[2].pass = res;
              if (res) {
                callback();
              } else {
                callback(new Error(_this.errorMessages[2].message));
              }
            },
            required: true,
            trigger: "blur",
          },
          {
            validator(rule, value, callback) {
              const res = _this.$store.getters.checkPassword(value);
              let pass = res === true;
              // _this.$set(_this.errorMessages[0], 'pass', res);
              _this.errorMessages[0].pass = pass;
              if (pass) {
                callback();
              } else {
                _this.errorMessages[0].message = res;
                callback(new Error(res));
              }
            },
            trigger: "blur",
          },
        ],
        affirmPassword: [
          {
            validator(rule, value, callback) {
              const res = _this.pwdFormData.password === value;
              _this.errorMessages[1].pass = res;
              // _this.$set(_this.errorMessages[1], 'pass', !res);
              if (!res) callback(new Error(_this.errorMessages[1].message));
              else callback();
            },
            required: true,
            trigger: "blur",
          },
        ],
      };
    },
  },
  mounted() {},
  methods: {
    // 解决浏览器自动填充表单的问题
    focusFn() {
      this.readonlyData = false;
    },
    closeDialog() {
      console.log("dialogVisible", this.dialogVisible);
      this.$nextTick(() => {
        if (this.$refs.forgetForm) {
          this.$refs.forgetForm.resetFields();
        }
      });
      this.$emit("close-dialog");
    },
    /**
     * 校验表单
     */
    checkForm() {
      return new Promise((resolve, reject) => {
        this.$refs.forgetForm.validate((valid, errors) => {
          if (valid) {
            resolve();
          } else {
            this.$message.info(errors[Object.keys(errors)[0]][0].message);
            reject();
          }
        });
      });
    },
    checkFormField(field) {
      return new Promise((resolve, reject) => {
        this.$refs.forgetForm.validateField(field, (errMsg) => {
          if (errMsg) {
            this.$message.info(errMsg);
            console.log("手机号校验未通过", errMsg);
            reject();
          } else {
            console.log("校验通过");
            resolve();
          }
        });
      });
    },
    /**
     * 发送验证码
     */
    sendValidate() {
      if (this.msgSending || this.sendSuccess) return;
      if (!this.pwdFormData.mobile) {
        this.$message.info(this.$t("el.forgotPwd.EnterMobileNumber") + "！");
        return;
      }
      this.msgSending = true;
      this.checkFormField("mobile")
        .then(() => sendSms(this.pwdFormData.mobile, this.pwdType))
        .then(({ data }) => {
          this.msgSending = false;
          // 标记发送成功
          this.sendSuccess = true;
          this.startCountdown();
          this.$message.success(this.$t("el.forgotPwd.VerificationSent"));
        })
        .catch(() => {
          this.msgSending = false;
        });
    },
    /**
     * 开始倒计时
     */
    startCountdown() {
      this.timing = 30;
      const clear = setInterval(() => {
        if (this.timing <= 1) {
          clearInterval(clear);
          // 取消标记发送成功
          this.sendSuccess = false;
        } else {
          this.timing -= 1;
        }
      }, 1000);
    },
    // 前往登录
    onGoLogin() {
      this.$router.push({
        path: "login",
      });
    },
    handleNext() {
      if (this.canSubmit) {
        this.canSubmit = false;
        let tFormData = JSON.parse(JSON.stringify(this.pwdFormData));
        tFormData.password = rsaEncrypt(tFormData.password);
        tFormData.affirmPassword = rsaEncrypt(tFormData.affirmPassword);
        let promise = this.checkForm()
          .then(() => updatePassword({ ...tFormData, type: this.pwdType }))
          .then(() => {
            this.canSubmit = true;
            this.$message.success(this.$t("el.forgotPwd.ResetSucceeded"));
            this.closeDialog();
            this.$emit("update-pwd-success");
          })
          .catch(() => {
            this.canSubmit = true;
          });
        // promise
        //   .then(() => {
        //     this.next();
        //   })
        //   .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.forget-dialog {
  .input-form {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px 20px;

    .steps-validate {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      /deep/ .el-form-item__label {
        width: 100px;
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        // line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      /deep/ .el-form-item__content {
        width: 320px;
        display: flex;
      }
      /deep/ .el-button--primary.is-disabled:hover,
      .el-button--primary.is-disabled:focus,
      .el-button--primary.is-disabled:active,
      .el-button--primary.is-disabled.is-active {
        color: #fff;
        background: #a0cfff;
        background: #a0cfff;
      }
    }

    .steps-validate-button {
      position: absolute;
      width: 94px;
      right: 0;
      top: 13px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      font-size: 14px;
      color: #4d71d7;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        height: 16px;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.09);
      }
      &.disabled {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.25);
        &::after {
          background-color: rgba(0, 0, 0, 0.25);
        }
      }
    }
    .steps-complete {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-top: 24px;
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
.en-status {
  .steps-validate {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    /deep/ .el-form-item__label {
      width: 140px !important;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      // line-height: 40px;
      padding: 0 12px 0 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    /deep/ .el-form-item__content {
      width: 520px !important;
      display: flex;
    }
  }
}
.next-btn {
  position: absolute;
  bottom: 105px;
  left: 100px;
  width: 240px;
  height: 40px;
  background-color: #4d71d7;
  border: none;
  text-align: center;
  color: #ffffff;
  border-radius: 2px;
  cursor: pointer;
}
.to-login-btn {
  position: absolute;
  bottom: 75px;
  left: 0;
  right: 0;
  line-height: 22px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.flex {
  display: flex;
}
</style>
<style lang="scss">
.dialog-class {
  min-width: 576px;
}
.en-status {
  .dialog-class {
    min-width: 776px;
  }
}
</style>
